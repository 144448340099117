/* eslint-disable no-unused-vars */

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    if (localStorage.getItem("btnText")) {
      document.body.style.setProperty(
        "--headBtn",
        localStorage.getItem("btnText")
      );
    }
    if (localStorage.getItem("btnbg")) {
      document.body.style.setProperty(
        "--btnBackgrnd",
        localStorage.getItem("btnbg")
      );
    }
    if (localStorage.getItem("bgcolor")) {
      document.body.style.setProperty(
        "--colDefault",
        localStorage.getItem("bgcolor")
      );
    }
    if (localStorage.getItem("cardBg")) {
      document.body.style.setProperty(
        "--colCardBg",
        localStorage.getItem("cardBg")
      );
    }
    if (localStorage.getItem("cardText")) {
      document.body.style.setProperty(
        "--colCardText",
        localStorage.getItem("cardText")
      );
    }
    if (localStorage.getItem("tableText")) {
      document.body.style.setProperty(
        "--colTableText",
        localStorage.getItem("tableText")
      );
    }
    if (localStorage.getItem("downloadText")) {
      document.body.style.setProperty(
        "--colDownloadText",
        localStorage.getItem("downloadText")
      );
    }
    if (localStorage.getItem("oddRow")) {
      document.body.style.setProperty(
        "--colOddRow",
        localStorage.getItem("oddRow")
      );
    }
    if (localStorage.getItem("evenRow")) {
      document.body.style.setProperty(
        "--colEvenRow",
        localStorage.getItem("evenRow")
      );
    }
    if (localStorage.getItem("SearchBox")) {
      document.body.style.setProperty(
        "--colSearchBox",
        localStorage.getItem("SearchBox")
      );
    }
    if (localStorage.getItem("BgGrayDark")) {
      document.body.style.setProperty(
        "--colBgGrayDark",
        localStorage.getItem("BgGrayDark")
      );
    }
    if (localStorage.getItem("fontSize")) {
      document.body.style.setProperty(
        "--CurrentFontSize",
        localStorage.getItem("fontSize")
      );
    }

    if (localStorage.getItem("tooltipTransform")) {
      document.body.style.setProperty(
        "--tooltipTransformY",
        localStorage.getItem("tooltipTransform") == "true" ? "-40px" : "0px"
      );
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_mixin.scss";
@import "@/assets/scss/_base.scss";

:root {
  /* CSS variables */
  //for light & dark themes
  --btnBackgrnd: white;
  --headBtn: #1f5799;
  --colDefault: #212121;
  --colPowderblue: powderblue;
  --colCardBg: rgb(165, 165, 165);
  --colCardText: black;
  --colTableText: white;
  --colDownloadText: #0190a3;
  --colOddRow: rgb(50, 50, 50);
  --colEvenRow: rgb(33, 33, 33);
  --colSearchBox: black;
  --colBgGrayDark: #bbb9c0;
  // ------------------------------------
  // --TestColor: #cbdecf;

  /* CSS variables */
  //for font sizes
  --CurrentFontSize: 14px;
  --tooltipTransformY: 0px;
  // --font16px: 16px;
  // --font18px: 18px;
}
</style>
