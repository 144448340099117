import Axios from "axios";
import radConfig from "../../config/config";

export const postDataPost = async (data) => {
  let bodyFormData = new FormData();
  // console.log(data.user_selected, data.password, data.email);
  bodyFormData.append("user_selected", data.user_selected);

  bodyFormData.append("email", data.email);
  bodyFormData.append("password", data.password);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/telerad/login-validation`,
    data: bodyFormData,
  };

  let fun = await Axios(config);
  return fun;
};

export const listItems = async (headers, page_size, page_num) => {
  let listFormData = new FormData();

  // listFormData.append("page_num", data);
  listFormData.append("page_size", page_size);
  listFormData.append("page_num", page_num);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/telerad/fetch-admin-list`,
    headers: headers,
    data: listFormData,
  };

  try {
    let fun = await Axios(config);
    return fun;
    
  } catch (error) {

    let error_status = error.response.status;

    if (error_status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("loginAs");
      window.location = "/";
    }
  }
};

export const listItemsDiag = async (headers, page_size, page_num) => {
  let listFormData = new FormData();

  // listFormData.append("page_num", data);
  listFormData.append("page_size", page_size);
  listFormData.append("page_num", page_num);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/diag/fetch-admin-list`,
    headers: headers,
    data: listFormData,
  };

  try {
    let fun = await Axios(config);
    return fun;
  } catch (error) {
    let error_status = error.response.status;

    if (error_status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("loginAs");
      window.location = "/";
    }
  }
};


export const listOperator = async (headers, page_size, page_num) => {
  let listFormData = new FormData();

  // listFormData.append("page_num", data);
  listFormData.append("page_size", page_size);
  listFormData.append("page_num", page_num);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/fetch-operator-list`,
    headers: headers,
    data: listFormData,
  };

  try {
    let fun = await Axios(config);
    return fun;
  } catch (error) {
    let error_status = error.response.status;

    if (error_status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("loginAs");
      window.location = "/";
    }
  }
};

export const paginationAPI = async (data, headers) => {
  let bodyFormData = new FormData();

  bodyFormData.append("page_num", data.pageNo);
  bodyFormData.append("page_size", data.pageSize);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/telerad/fetch-admin-list`,
    data: bodyFormData,
    headers: headers,
  };

  let fun = await Axios(config);
  return fun;
};

export const paginationAPIdiag = async (data, headers) => {
  let bodyFormData = new FormData();

  bodyFormData.append("page_num", data.pageNo);
  bodyFormData.append("page_size", data.pageSize);

  var config = {
    method: "post",
    url: `${radConfig.baseUrl}/diag/fetch-admin-list`,
    data: bodyFormData,
    headers: headers,
  };

  let fun = await Axios(config);
  return fun;
};
